import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { convertToCamel, convertToSnake } from 'state-domains/utils/typeCast';

import { buildConfigurationProjectActivityUrl } from '../../../network/urls';
import { sendRequestWithXSRFToken } from '../../../utils';
import { EditConfigurationProjectActivity } from '../types';

export const modifyConfigurationProjectActivity = (
    projectId: string,
    payload: EditConfigurationProjectActivity,
): Observable<EditConfigurationProjectActivity> => {
     
    delete payload.type;
    const snakePayload = convertToSnake(payload);
    const obs = sendRequestWithXSRFToken(
        buildConfigurationProjectActivityUrl(projectId, payload.id),
        snakePayload,
        'POST',
    );
    return obs.pipe(
        switchMap(({ response }: AjaxResponse<EditConfigurationProjectActivity>) => {
            const result = convertToCamel<EditConfigurationProjectActivity>(response);
            return observableOf(result);
        }),
    );
};
